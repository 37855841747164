import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes,Route, Link } from "react-router-dom";
import AdminHeaderNav from '../admin/adminheader';
import axios from "axios";
import { Table } from "react-bootstrap";
import AllMemberTableRows from "../admin/membertablerows";
 

const AdminAllMemberList = () => 
{
  const [searchText, setSearchInputText] = useState('');

const [allmembers, setAdminAllMembers] = useState([]);

  
const searchTextItem = (searchInText) => {
	setSearchInputText(searchInText)
}

useEffect(() => {

	axios.get('https://codes.biglittleideas.com/bniapps/api/bni-apis.php?searchbymembertxt='+searchText, { 
    params: {
        action:'getallMemberslist'
      }
   })
    .then((res) => {
        setAdminAllMembers(res.data.records);
     })
 
 }, [searchText]);

const MemberGridTable = () => {
	return allmembers.map((res, i) => {
  	  return <AllMemberTableRows obj={res} key={i} />;
	});
};

return (

   <>  
    <AdminHeaderNav />

<div className="container">
  
  <div className="text-left p-4">
       <h3>All Members</h3>
  </div>


<section className="bg-light p-5">
  <div className="row">
    <div class="col">
      <input type="text" className="form-control"   placeholder="Search By name" onMouseOut={(e) => searchTextItem(e.target.value)} /> 
    </div>
    <div class="col-lg-2 col-md-2 col-xs-12">
      <button class="btn btn-danger"   >Search </button> 
    </div>

    <div className="col">
      <select className="form-control" >
        <option value="">Search By Status</option>
        <option value="10">New</option>
        <option value="30">Active</option>
        <option value="40">Inactive</option>
      </select>
   </div>
 </div>

</section>

<div className="row" style={{marginTop:"5%"}} >
    <Table striped bordered hover>
		<thead>
		<tr>
			<th>Name</th>
			<th>Phone</th>
			<th>Email</th>
			<th>Action</th>
 		</tr>
		</thead>
		<tbody>{MemberGridTable()}</tbody>
	</Table>
	</div>
 </div>	
 

 </>

);
};

export default AdminAllMemberList;