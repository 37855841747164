import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import NewHeaderNav from './newheader';
import FooterNav from './footer';


const ContactPage = () =>
{
   return (
   <>

  <NewHeaderNav />

<section class="bgimage ">
 <div class="container-fluid container-sm">
  <div class="row">
    <div class="col-12 py-5  text-center heading-banner">
     <h5>Contact Us</h5>
    </div>
   </div>
 </div>
</section>

  <div class="container-fluid bg-light">
    {/* <div class="container">
      <div class="container-fluid"> */}
        <div class="row py-5">
          <div class="  col-lg-6 col-md-12 col-sm-12 col-xs-12 bg-gradient ">
            
              <h3 class="pb-4">Get In Touch With Us</h3>
              <div class="contact-details p-4">
                <h5><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">  
                {/* Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.   */}
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>Address: P.O.Box: 167 Dubai, United Arab<br/> Emirates</h5>
                <h5><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> {/* Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>Phone Number: 982648689773</h5>
                <h5><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"> 
                {/* Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>Email Address:abcd@gmail.com</h5>
              </div>
          </div>

          <div class="  col-lg-6 col-md-12 col-sm-12  col-xs-12 py-4  border">
           <form>
              <div class="mb-3">
                <label for="staticEmail2" class="sr-only ">Name</label>
                <input type="text" class="form-control" placeholder="Name" />
              </div>
              <div class="mb-3">
                 <label for="staticEmail2" class="sr-only ">Email</label>
                 <input type="email" class="form-control" id="inputEmail3" placeholder="Email" />
              </div>
              <div class="mb-3 ">
                 <label for="staticEmail2" class="sr-only ">Company Name</label>
                 <input type="text" class="form-control" id="inputEmail3" placeholder="Company Name" />
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      {/* </div>
    </div> */}
  </div>

  <FooterNav />

  </>
  
    )
  
  }

  export default ContactPage;