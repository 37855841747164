import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
//import HeaderNav from './header';
import NewHeaderNav from './newheader';
import FooterNav from './footer';
import { useState , useEffect } from "react";
import axios from "axios";
import {useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const MemberRegister = (props) =>
{
   const navigate = useNavigate();
    //  console.log(props.fileName)
    const[allFormQuestions , setAllQuestions] = useState([{ hidQuestionId: "", txtAnswer : ""}]);
    const [allFormQuestAnsarray, setSelQuestAnswers] = useState([]);
    const [arrQuestAnswers ,arrSelQuestionAnswers] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectSpouseFile, setSpouseImgSelect] = useState([]);
    const [uploadSpouseProgress, setSpouseUploadProgress] = useState(0);
   
    //const [IsLoginTrue, setIsLoginTrue] = useState();
   const [IsNotLoginText, setIsNotLoginText] = useState();
    
    const [MySelProfileImage ,SetMySelProfileImage] = useState();
    //const [allFormQuestion, setFormQuestValues] = useState([{ hidQuestionId: "", txtAnswer : ""}])
 
   const [allValues, setAllValues] = useState({
        txtName: '',
        txtEmail: '',
        txtPhone: '',
        txtCompanyName:'',
        txtAddress:'',
        txtDesignation:'',
        txtSpouseInfo:'',
        txtMemberDescription:''
     });


     useEffect(() => {
    
      const localSessionGroupId = localStorage.getItem('localsessiongroupid')
       
        if (localSessionGroupId == null)
        { 
           //alert("if");
           //setIsLoginTrue(false);
           setIsNotLoginText(<div class="col">
                       <h4><strong>You are not logged in, Kindly Login</strong></h4> <Link to="/login" class="nav-link active" aria-current="page"><strong> Login Here </strong> </Link>
                  </div>   )
        }
        else
        {
           //alert("token not null");
            //setIsLoginTrue(true);
            getAllQuestionList();
        }
    
     
    }, []);
    
     const getAllQuestionList = () =>
     {
      axios.get('https://codes.biglittleideas.com/bniapps/api/bni-apis.php', { 
        params: {
          action:'getMemberAllQuestions'
          }
      })
      .then((res) => {
         setAllQuestions(res.data.records);
      })
    
     }


    const changeHandler = e => 
    {
        setAllValues({...allValues, [e.target.name]: e.target.value})
    }


    const handleFileChange = (event) => {
      const files = Array.from(event.target.files);
      
        //setSelectedFiles(files[0].name);  
      // console.table(files);

        // console.log(files[0].name)
  
      const formData = new FormData();
        files.forEach((file) => {
          formData.append('files', file);
      });
  

      const xhr = new XMLHttpRequest();

      xhr.open('POST', 'https://codes.biglittleideas.com/bniapps/api/fileuploader.php', true);
      
    
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(progress);
        }
      });
  
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          // Upload completed
           //console.table(xhr.responseText);
            var response = JSON.parse(xhr.responseText);
          console.log(response.filename);

          setSelectedFiles(response.filename);
        }
      };
        //console.log(formData);
        xhr.send(formData);
  
  };

////////////////////////////////////////////////////////////////////

  const handleSpouseFileChange = (event) => {
    const files = Array.from(event.target.files);
    
    const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
    });


    const xhr = new XMLHttpRequest();

     xhr.open('POST', 'https://codes.biglittleideas.com/bniapps/api/fileuploader.php', true);
    
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setSpouseUploadProgress(progress);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        // Upload completed
         //console.table(xhr.responseText);
          var response = JSON.parse(xhr.responseText);
          //console.log(response.filename);

        setSpouseImgSelect(response.filename);
      }
    };
      //console.log(formData);
      xhr.send(formData);

};



  // const handleChange = (i, e) => {
  //     let newFormValues = [...allFormQuestions];
  //     newFormValues[i][e.target.name] = e.target.value;
  //     setAllQuestions(newFormValues);
  //   }


  function updateOrInsertKeyValuePair(array, key, value) 
{
   const objectToUpdate = array.find(obj => obj.key === key);
      if (objectToUpdate) 
      {
         //alert("if");
        objectToUpdate.value = value;
      } else {
        //alert("else");
        const newObject = { key: key, value: value  };
        array.push(newObject);
      }
      setSelQuestAnswers(array);
}

const handleChange = (questid,e) => 
{
      const questionansdata = {};
    updateOrInsertKeyValuePair(allFormQuestAnsarray,questid,e.target.value);
      
    allFormQuestAnsarray.map((opt) => 
    {  
        //console.table(opt);
         Object.assign(questionansdata, { [opt.value]: opt.key });
     })
       console.table(questionansdata);
       arrSelQuestionAnswers(questionansdata);
}
 



const handleSubmit = event => 
{ 
   event.preventDefault();
    const formData = new FormData(event.target); // reference to form element
     //console.table(formData);      
    const data = {}; // need to convert it before using not with XMLHttpRequest
    
         for (let [key, val] of formData.entries()) 
          {
            Object.assign(data, { [key]: val })
          }
          
          Object.assign(data, { ['action']:'saveMemberRegisData' });
          Object.assign(data, { ['arrFormQuestAnsData']:arrQuestAnswers });
          Object.assign(data, { ['profileImage']:selectedFiles })
          Object.assign(data, { ['spouseProfileImage']:selectSpouseFile })

                     //console.log(data);
          axios.post("https://codes.biglittleideas.com/bniapps/api/bni-apis.php", data)      
          .then(res => 
          {   
               console.log(res.data.status);
               if (res.data.status == true)
               {
                  alert(res.data.outputval);
                  navigate("/usermemberdetail/"+res.data.outputval );
               }
               else
               {
                  alert(res.data.message);
               }


               //alert('Member successfully created');
                 //props.history.push("/student-list");
                //navigate("/allusers");     
          })
}

   return (
    <>
      <NewHeaderNav />

      {/* {IsNotLoginText} */}
   
      

  <div className="container mt-5 bg-light p-3 ">
  <div className="text-center p-4">
    <h3>Member Registration Form</h3>
  </div>
   
   <form onSubmit={handleSubmit}>
     <div className="row">
      <div className="col" style={{textAlign: 'left'}}>
        <label for="staticEmail2" className="sr-only"  > Name</label>
         <input type="text" name="txtName" className="form-control" placeholder="Name" onChange={changeHandler} />
      </div>
      <div className="col">
        <label for="staticEmail2" className="sr-only ">Email</label>
        <input type="email" name="txtEmail" className="form-control" id="inputEmail3" placeholder="Email" onChange={changeHandler} />
      </div>
     </div>
     <div className="row mt-3">
      <div className="col">
        <label for="staticEmail2" className="sr-only ">Mobile Number</label>
        <input type="text" name="txtPhone" className="form-control" placeholder="Mobile Number" onChange={changeHandler} />
      </div>
      <div className="col">
        <label for="staticEmail2" className="sr-only ">Company Name</label>
        <input type="text" name="txtCompanyName" className="form-control" id="inputEmail3" placeholder="Company Name" onChange={changeHandler} />
      </div>

      <div className="col">
        <label for="staticEmail2" className="sr-only ">Designation</label>
        <input type="text" name="txtDesignation" className="form-control" id="inputEmail3" onChange={changeHandler} />
      </div>
     </div>
   <div className="row mt-3">
      <div className="col">
       <label for="staticEmail2" className="sr-only ">About Spouse</label>
         <textarea name="txtSpouseInfo" className="form-control" rows="3"   onChange={changeHandler}></textarea>
      </div>

      <div className="col">
         <label for="staticEmail2" className="sr-only ">Address</label>
        <textarea name="txtAddress" className="form-control" id="exampleFormControlTextarea1" rows="3"   onChange={changeHandler}></textarea>
      </div>

      <div className="col">
       <label for="staticEmail2" className="sr-only ">About Me</label>
         <textarea name="txtMemberDescription" className="form-control" rows="3"   onChange={changeHandler}></textarea>
      </div>

   </div>

    <div className="row mt-3">
      <div className="col">
       <label for="staticEmail2" className="sr-only ">Member Profile Image</label>
         <input type="file" multiple onChange={handleFileChange} />
         <div>{uploadProgress}% Uploaded    ---- {selectedFiles} </div>
      </div>

      <div className="col">
       <label for="staticEmail2" className="sr-only ">Spouse Image</label>
         <input type="file" multiple onChange={handleSpouseFileChange} />
         <div>{uploadSpouseProgress}% Uploaded    ---- {selectSpouseFile} </div>
      </div>

   </div> 

  
{ allFormQuestions && allFormQuestions.map((question,index) => (
  <div className="row pt-4">
           
    <div className="col" key={index} style={{textAlign: 'left'}} >
      {question.memberquestiontext}
        
      <input type="hidden" name="hidQuestionId" value={question.memberquestionid} />
        <textarea rows="2" name="txtAnswer" className="form-control"   onChange={e => handleChange(question.memberquestionid, e)} />
    </div>
 </div>
))}


   <div className="row mt-3">
    <div className="col">
      <input type="submit" class="btn btn-primary" />
    </div>
  </div>
  
  </form>
</div>

<FooterNav />    
    
    </>

   )

}


export default MemberRegister;