import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { useState , useEffect } from "react";


const NewHeader = () =>
{
  const [IsLoginTrue, setIsLoginTrue] = useState(false);

  useEffect(() => {

   const localSessionGroupId = localStorage.getItem('localsessiongroupid')
     
      if (localSessionGroupId != null)
      { 
         setIsLoginTrue(true);
      }
      else
      {
        setIsLoginTrue(false);
      }
  } , [])


  return (
    
    <>
<nav class="navbar navbar-expand-lg navbar-light bg-light ">
    <div class="container-fluid container-sm">
      <a class="navbar-brand" href="#">
        <img src="/assets/images/logo.jpg" class="rounded" alt="Cinque Terre" /> 
     </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center col-sm-6 py-4 col-lg-6 col-md-12 col-sm-12 col-xs-12" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <Link to="/" class="nav-link active" aria-current="page" >Home</Link>
          </li>
          <li class="nav-item">
           <Link to="/aboutus" class="nav-link active" aria-current="page" >About Us </Link>
          </li>

          <li class="nav-item">
          <Link to="/contact" class="nav-link active" aria-current="page" >Contact</Link>
          </li>
          
          

       <li class="nav-item">
          <Link to="/allmembers" class="nav-link active" aria-current="page" > All Members </Link>
       </li>


        </ul>
      </div>


     {IsLoginTrue ? <Link to="/logout" class="nav-link active" aria-current="page" >Logout</Link> : <Link to="/login" class="nav-link active" aria-current="page" >Login</Link>}   
         
       

      {/* <a href="#" className="fa fa-facebook"></a>
      <a href="#" class="fa fa-twitter"></a>
      <a href="#" class="fa fa-skype"></a> */}

    </div>
  </nav>

  </>

  )

}

export default NewHeader