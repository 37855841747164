import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () =>
{

  return (
    
    <>
<div class="container-fluid bg-dark pb-5 ">
    <div class="container pt-5">
      <div class="row">
        <div class="col-sm">
           <a class="navbar-brand" href="#">
            <img src="assets/images/logo_contact.jpg" class="rounded" alt="Cinque Terre" width="250" height="150" /> </a>
           <p class="text-white py-3">The Company is a well-established name for the range of security products</p>
        </div>
        <div class="col-sm text-white">
          <h3>Quick Links</h3>
          <h6>Home</h6>
          <h6>About</h6>
          <h6>Services</h6>

          <h6>Contact Us
          </h6>
        </div>
        <div class="col-sm text-white">
          <h3>Contact Info</h3>
           <h6>Address: P.O.Box: 167 Dubai, United Arab Emirates</h6>
           <h6>Phone Number: 982648689773</h6>
           <h6>Email Address:abcd@gmail.com</h6>
        </div>
      </div>
    </div>
  </div>
 </>
)

}

export default Footer