import logo from './logo.svg';
import './App.css';

import AdminLogin from './components/admin/adminlogin';
import AdminAllMembersList from './components/admin/adminallmembers';




import SystemLogin from './components/login';
import SystemLogout from './components/logout';
import PathProtected from './components/protected';

import NewPathProtected from './components/newprotected';
import MemberRegistration from './components/member-register';
import MembersList from './components/all-memberslists';
import AddQuestions from './components/add-questions';
//import FileUploader from './components/fileuploader';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AllMembersList from './components/all-memberslists';
import MemberAllInfo from './components/member-detail';
import HomePage from './components/home';
import AboutusPage from './components/aboutus';
import ContactPage from './components/contact';



// import { useNavigate } from "react-router-dom";
// import { useState } from "react";
// import axios from "axios";

function App() {


  window.addEventListener('unload', function() {
    // Clear a specific item from local storage
     //localStorage.removeItem('localsessiongroupid');
  });

  //const [isLoggedIn, setIsLoggedIn] = useState(false);
   
  //const navigate = useNavigate();

  // const handleLoginSubmit = event => 
  // { 
  //     event.preventDefault();
  //     const formData = new FormData(event.target); // reference to form element
  //    //console.table(formData);      
  //     const data = {}; // need to convert it before using not with XMLHttpRequest
    
  //       for (let [key, val] of formData.entries()) 
  //       {
  //           Object.assign(data, { [key]: val })
  //       }
      
  //       Object.assign(data, { ['action']:'sendMemberLoginData' });   
  //         //console.log(data);
  //       // alert(allLoginValues.txtEmail); 
        
  //       axios.post("https://codes.biglittleideas.com/bniapps/api/bni-apis.php", data)      
  //         .then(res => 
  //         {   
  //              console.log(res.data.status);
  //              if (res.data.status == true)
  //              {
  //                 setIsLoggedIn(true);
  //                 alert(res.data.message);
  //                 // navigate("/register");
  //              }
  //       })
  //         .catch(err => console.log(err));
    
  //   }
 

  return (
    <div className="App">

    {/* <FileUploader /> */}

   <BrowserRouter> 
     <Routes>


    {/* --------------------------- ADMIN PATH ---------------------------------------------------  */}

    <Route path="/admin/login" element={<AdminLogin />} />

    <Route path="/admin/allmemberslist" element={<AdminAllMembersList />} />











{/* ---------------------------------- USERAREA PATH ---------------------------------------------------------- */}
        
     <Route path="/" element={<HomePage />} />
      
     <Route path="/contact" element={<ContactPage />} /> 
     
     <Route path="/aboutus" element={<AboutusPage />} />

       <Route path="/login" element={<SystemLogin />} >
          {/* <MemberLogin handleLoginFun={handleLoginSubmit} /> */}
       </Route>
       
      <Route path="/logout" element={<SystemLogout />}  />

       <Route path="/register" element={<NewPathProtected cmp = {MemberRegistration} />}  />

       <Route path="/addquestion" element={<AddQuestions />} />

       <Route path="/allmembers" element={<AllMembersList />} />

       <Route path="/usermemberdetail/:memberid" element={<NewPathProtected cmp = {MemberAllInfo}  />} />


    

     
      

     


     </Routes>

     
   </BrowserRouter>

     


    </div>
  );
}

export default App;
