import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//import axios from "axios";

const AllMemberTableRow = (props) => 
{
     console.table(props);
const { memberid, membername, memberphone, memberemail} = props.obj;

 
 
return (
	<tr>
	  <td>{membername}</td>
	  <td>{memberphone}</td>
	  <td>{memberemail}</td>  
	  <td>
		<Link className="edit-link">Update</Link>
	 </td>
	</tr>
);
};

export default AllMemberTableRow;