import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
//import HeaderNav from './header';
import NewHeaderNav from './newheader';
import FooterNav from './footer';
//import { useState , useEffect } from "react";


const HomePage = () =>
{
   return (
   <>  
  {/* <HeaderNav /> */}

  <NewHeaderNav />

<section class="bgimagehome">
<div class="container-fluid container-sm">
  <div class="row">
    <div class="col-12 py-5  text-center">
      <h5>Hello, world! Full width Hero-unit header</h5>
      <p class="text-white px-5 py-3">This is a template for a simple marketing or informational website. It includes a large callout called the hero unit and three supporting pieces of content. Use it as a starting point to create something more unique.</p>
      <p><a href="#" class="btn bg-dark text-white btn-large">Learn more »</a></p>
    </div>
  </div>
</div>
</section>

<div class="container ">
    <div class="featured-text  pt-5  text-center">
      <h2>Featured</h2>
    </div>
    <div class="row">
      <div class=" col-lg-6 col-md-12 col-sm-12 col-xs-12 py-4">
        <div class="card">
          <img src="assets/images/About-Us_bootsrap.jpg" class="rounded" alt="Cinque Terre" width="600" height="236" /> 
        </div>
      </div>
      <div class="col-lg-6  col-md-12 col-sm-12 col-xs-12 py-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">About Us</h5>
            <p class="card-text py-3">The Company is a well-established name for the range of security products, office storage, and Material Handling equipment, Shelving System, Racking System, Mobile Storage System, Garage Equipment, Fuel Pumps, Swimming Pool & Garden Furniture.</p>
            <a href="#" class="btn bg-dark text-white">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card-featured text-center p-5">
    <div class="card-header text-white  text-center featured-text-title">
      Featured
    </div>
    <div class="card-body">
      <h5 class="card-title text-white ">Special title treatment</h5>
      <p class="card-text text-white card-para pt-2">With supporting text below as a natural lead-in to additional content.</p>
      <a href="#" class="btn bg-dark text-white">Go somewhere</a>
    </div>
  </div>
 
  <div class="container">
    <div class="featured-text  pt-5  text-center">
      <h2>Services</h2>
    </div>
    <div class="row my-5">
        <div class="col-sm-4 py-4  border" >
          <div class="card">
            <img class="card-img-top" src="assets/images/services.jpg" alt="Card image cap" />
            <div class="card-body">
              <h3 class="text-center">It Services</h3>
              <p class="card-text card-services">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <div class="text-center">
                <a href="#" class="btn bg-dark text-white text-center">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 py-4 border" >
          <div class="card">
            <img class="card-img-top" src="assets/images/services.jpg" alt="Card image cap" />
            <div class="card-body">
              <h3 class="text-center">It Services</h3>
              <p class="card-text card-services">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <div class="text-center">
                <a href="#" class="btn bg-dark text-white text-center">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 py-4 border" >
          <div class="card">
            <img class="card-img-top"  src="assets/images/services.jpg" alt="Card image cap" />
            <div class="card-body">
              <h3 class="text-center">It Services</h3>
              <p class="card-text card-services">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <div class="text-center">
                <a href="#" class="btn bg-dark text-white text-center">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div class="container-fluid bg-light">
    {/* <div class="container">
      <div class="container-fluid"> */}
        <div class="featured-text  py-3  text-center">
          <h2>Contact Us </h2>
        </div>
        <div class="row">
        
          <div class="col-sm-6  col-lg-6 col-md-12 col-sm-12 col-xs-12 bg-gradient ">
            <h3 class="p-3">Get In Touch With Us</h3>
              <div class="contact-details p-4">
                <h5>Address: P.O.Box: 167 Dubai, United Arab <br />Emirates</h5>
                <h5>Phone Number: 982648689773</h5>
                <h5>Email Address:abcd@gmail.com</h5>
              </div>
          </div>

          <div class="col-sm-6  col-lg-6 col-md-12 col-sm-12 col-xs-12 py-4 mb-5 border">
           <form>
              <div class="mb-3">
                <label for="staticEmail2" class="sr-only ">Name</label>
                <input type="text" class="form-control" placeholder="Name" />
              </div>
              <div class="mb-3">
                 <label for="staticEmail2" class="sr-only ">Email</label>
                 <input type="email" class="form-control" id="inputEmail3" placeholder="Email" />
              </div>
              <div class="mb-3 ">
                 <label for="staticEmail2" class="sr-only ">Company Name</label>
                 <input type="text" class="form-control" id="inputEmail3" placeholder="Company Name" />
              </div>
              <button type="submit" class="btn bg-dark text-white">Submit</button>
            </form>
          </div>
        </div>
      {/* </div>
    </div> */}
  </div>


  <FooterNav />

   </>


 )

}

export default HomePage;