import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
//import HeaderNav from './header';
import NewHeaderNav from './newheader';
import FooterNav from './footer';
import { useState , useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";


const AllMembersList = (props) =>
{
    //console.log(props.Isloginvar);
  const [IsLoginTrue, setIsLoginTrue] = useState();
  const [IsNotLoginText, setIsNotLoginText] = useState();
  const [loggedinText, setloggedinText] = useState();

  const [allMembersArray, setAllMembers] = useState([]);
  const [searchMemberText,setSearchInputText] = useState(''); 


  // const searchTextItem = (searchInText) => 
  // {
  //   setSearchInputText(searchInText)
  //   //console.log(searchInText); 
  // }

  const searchTextItem = (event) => {
    setSearchInputText(event.target.value);
  };

  const handleButtonClick = () => 
  {
      getMemberListFunction();
  };


  const getMemberListFunction = () => 
  {
    axios.get('https://codes.biglittleideas.com/bniapps/api/bni-apis.php?searchbymembertxt='+searchMemberText, { 
    params: {
        action:'getallMemberslist',
      }
   })
    .then((res) => {
        setAllMembers(res.data.records);
     })

}


  useEffect(() => {
     
      //alert(props.Isloginvar);

    const localSessionGroupId = localStorage.getItem('localsessiongroupid')
     
      if (localSessionGroupId == null)
      { 
         //alert("if");
         
         setIsLoginTrue(false);

    //     const notLoggedinTextRows = `<div class="col">
    //       <h4><strong>You are not logged in, Kindly Login</strong></h4> 
    //       <Link to="/login" class="nav-link active" aria-current="page"><strong> Login Here </strong> </Link>
    //  </div>`;
              
        setIsNotLoginText(<div class="col">
             <h4><strong>You are not logged in, Kindly Login</strong></h4> 
              <Link to="/login" class="nav-link active" aria-current="page"><strong> Login Here </strong> </Link>
           </div>);
      }
      else
      {
         //alert("token not null");
          setIsLoginTrue(true);
          
      setloggedinText(
          <div class="col">
            <h2>Our People</h2> &nbsp;&nbsp; 
            <p class="people-text w-75 d-inline-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <Link to="/register" class="nav-link active" aria-current="page"><b> Register Here </b> </Link>
          </div>
       );
            getMemberListFunction();
      }

  }, [searchMemberText]);

return ( 
<>
   
<NewHeaderNav />

{/* {IsNotLoginText} */}
 
<section className="bgimage ">
    <div class="container-fluid container-sm">
      <div class="row">
        <div class="col-12 py-5  text-center heading-banner">
          <h5>Testimonial</h5>          
        </div>
      </div>
    </div>
</section>

<div class="container-fluid bg-light">
    {/* <div class="container">
      <div class="container-fluid"> */}
        <div class="featured-text  py-3  text-center">
           <div class="row mt-3">
              {IsLoginTrue ? loggedinText : IsNotLoginText } 
           </div>
        </div>
 
        <div class="row mt-5">
          <div class="col-lg-6 col-md-6 col-xs-12">
            <input type="text" className="form-control" placeholder="Search By name or email" onChange={searchTextItem}   />
          </div>
          <div class="col-lg-2 col-md-2 col-xs-12">
            <button class="btn btn-danger" onClick={handleButtonClick} >Search </button> 
          </div>
        </div>
     
          
      <div class="row">
        { allMembersArray && allMembersArray.map((member,index) => (       
    
      <div class="col-lg-3 col-md-3 col-xs-3 col-sm-3 py-2">
        <div class="card">
          <div class="card-body">
           
            {/* <img src="assets/images/testi_image.png" class="rounded" alt="Cinque Terre" />  */}
          
    {member.memberprofileimg != null ? <img src={member.memberprofileimg} class="rounded" alt="Cinque Terre" /> : <img src="assets/images/testi_image.png" class="rounded" alt="Cinque Terre" /> }
     <Link to={"/usermemberdetail/"+member.memberid}  ><h5 class="card-title text-center pt-3">{member.membername}</h5></Link>
     
            <h6 class="text-danger text-center">{member.memberdesignation}</h6>
            <p class="card-text text-center">{member.memberinfo}</p>
            {/* <div class="social-icons">
              <a href="#" class="fa fa-facebook"></a>
              <a href="#" class="fa fa-twitter"></a>
              <a href="#" class="fa fa-google"></a>
            </div> */}
          </div>
        </div>
      </div>
 ))}
</div>
      {/* </div>
    </div> */}
</div>

  <FooterNav />

  </>

 )

}

export default AllMembersList;