import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminHeaderNav from '../admin/adminheader';
//import FooterNav from '../footer';
import {useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";


const AdminLogin = (props) =>
{
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [userpassword, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const handleLoginSubmit = event => 
  { 
      event.preventDefault();
      const formData = new FormData(event.target); // reference to form element
     //console.table(formData);      
      const data = {}; // need to convert it before using not with XMLHttpRequest
    
        for (let [key, val] of formData.entries()) 
        {
            Object.assign(data, { [key]: val })
        }
      
        Object.assign(data, { ['action']:'sendAdminLoginData' });   
          //console.log(data);
        // alert(allLoginValues.txtEmail); 
        
        axios.post("https://codes.biglittleideas.com/bniapps/api/bni-apis.php", data)      
          .then(res => 
          {   
               console.log(res.data.status);
               if (res.data.status == true)
               {
                   setIsLoggedIn(true);
                  
                  //setIsLoggedIn(res.data.outputarr['SessionMemberGroupID']);

                  localStorage.setItem('localsessionmemberid', res.data.outputarr['SessionMemberID'] );
                    navigate("/admin/allmemberslist");
               }
               else
               {
                  setIsLoggedIn(false);
                   alert(res.data.message);   
               }
        })
          .catch(err => console.log(err));
  }

  //const handleSubmit = (username,userpassword) => 
  //{
    //const userInfo = { username: username, userpassword: userpassword };

      //event.preventDefault();
      //  <PathProtected usercrenditials = {event} />;
     
     // <PathProtected usercrenditials = { userInfo } />;

 //}


    return (
        <>

  {/* <AllMembersList Isloginvar = {isLoggedIn} /> */}

          <AdminHeaderNav />

      <div className="container mt-5 bg-light p-3 ">
      <div className="text-center p-4">
        <h3>Admin Login Here  </h3>
      </div>
       
       <form onSubmit={handleLoginSubmit}>

         <div className="row">
          <div className="col">
            <label for="staticEmail2" className="sr-only ">Email</label>
            <input type="email" name="txtAdminLoginEmail" className="form-control"  placeholder="Email"  onChange={(e) => setUsername(e.target.value)} />
          </div>
         </div>
         <div className="row mt-3">
          <div className="col">
            <label for="staticEmail2" className="sr-only ">Password</label>
             <input type="password" name="txtAdminLoginPassword" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
          </div>
         </div>
          
       <div className="row mt-3">
        <div className="col">
          <input type="submit" class="btn btn-primary" value="Login" />
        </div>
      </div>
      
      </form>
    </div>
        

      {/* <FooterNav />   */}
        
    </>
    
  )

}

export default  AdminLogin;