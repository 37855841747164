import React,{useEffect,useState} from  'react';
import { useNavigate  } from 'react-router-dom';
import axios from "axios";
import MemberLogin from './login';

function Protected (props)
{
  const Navigate = useNavigate ();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
    
    //let event = props.usercrenditials;

       alert(props);

  const handleLoginSubmit = event => 
  { 
      event.preventDefault();
      const formData = new FormData(event.target); // reference to form element
     //console.table(formData);      
      const data = {}; // need to convert it before using not with XMLHttpRequest
    
        for (let [key, val] of formData.entries()) 
        {
            Object.assign(data, { [key]: val })
        }
      
        Object.assign(data, { ['action']:'sendMemberLoginData' });   
          //console.log(data);
        // alert(allLoginValues.txtEmail); 
        
        axios.post("https://codes.biglittleideas.com/bniapps/api/bni-apis.php", data)      
          .then(res => 
          {   
               console.log(res.data.status);
               if (res.data.status == true)
               {
                  alert(res.data.message);
                  return setIsLoggedIn(true);
                  // navigate("/register");
               }
        })
          .catch(err => console.log(err));
    
    }


    return (

          <div>
             
             {/* <MemberLogin handleLoginFun={handleLoginSubmit} />   */}

          </div>
    )

}

export default Protected