import React,{useEffect,useState} from  'react';
import { useNavigate  } from 'react-router-dom';

function NewProtected (props)
{
  const Navigate = useNavigate ();
    
  let Component = props.cmp


    useEffect(() => {
      
        const localSessionGroupId = localStorage.getItem('localsessiongroupid')
 
        if (!localSessionGroupId)
        { 
          Navigate('/login');
        }

    } , [] );


    return (

          <div>
             
             <Component />

          </div>
    )

}

export default NewProtected