import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {  useEffect } from "react";

const LogoutButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://codes.biglittleideas.com/bniapps/api/bni-apis.php', { 
    params: {
        action:'getlogout'
      }
   })
    .then((res) => {
        sessionStorage.clear();
        localStorage.clear();
          
        navigate('/login');

     })
   
  }, []);


   
  return (
    <>
        {/* <button onClick={handleLogout}>Logout</button> */}
  </>

    );
};

export default LogoutButton;