import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from "react-router-dom";
import NewHeaderNav from './newheader';
import FooterNav from './footer';
import { useState , useEffect } from "react";
import axios from "axios";

const MemberDetailPage = (props) =>
{
    const params = useParams();
    const {memberid} = params;
    
    const [MemberInfoData, setMemberInfo] = useState([]);
    const [MemberQuesAnswersData, setMemberQuestAnsInfo] = useState([]);

  useEffect(() => {
    axios.get('https://codes.biglittleideas.com/bniapps/api/bni-apis.php', { 
    params: {
        searchmemberid:memberid,
        action:'getMemberAllInfo'
      }
   })
    .then((res) => {
        setMemberInfo(res.data.records[0]);
        setMemberQuestAnsInfo(res.data.recordarray);
     })
   
  }, []);


return (
<>

<NewHeaderNav />

  <section class="bgimage ">
    <div class="container-fluid container-sm">
      <div class="row">
        <div class="col-12 py-5  text-center heading-banner">
          <h5>Member Detail</h5>
        </div>
      </div>
    </div>
  </section>


<div class="container-fluid bg-light main-banner">
     
        <div class="row">
          <div class="col-lg-4 col-md-4 col-xs-4 col-sm-4 py-2">
            <div class="card">
              <div class="card-body">
              {/* <img src="../../public/assets/images/testi_image.png" class="rounded" alt="Cinque Terre" />  */}

              <img class="card-img" src = {MemberInfoData.memberprofileimg}  height="480px;" width="380px"  />
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 py-2">
            <div class="card">
              <div class="card-body">
                
                <h2 class="card-title text-danger pt-3">{MemberInfoData.membername}  </h2>
                <h3 class="text-danger  border-top">{MemberInfoData.memberdesignation}</h3>
                <h3 class="text-danger">{MemberInfoData.membercompany}</h3>
                <h6 class="text-danger">Contact Number : {MemberInfoData.memberphone}</h6>
                <h6 class="text-danger  ">Email : {MemberInfoData.memberemail} </h6>
                <h6 class="text-danger  ">Address - {MemberInfoData.memberaddress}</h6>
                {/* <div class="social-icons ">
                  <a href="#" class="fa fa-facebook"></a>
                  <a href="#" class="fa fa-twitter"></a>
                  <a href="#" class="fa fa-google"></a>
                </div> */}
              </div>
            </div>
          </div>
         
        </div>
      
{/* <div class="container pb-5"> */}
      <div class="row justify-content-start py-2">

   {MemberQuesAnswersData && MemberQuesAnswersData.map((answer) => (     
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
          <div class="text-col">
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" /> {/*  Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.*/}

            <path d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416V96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4L224 214.3V256v41.7L52.5 440.6zM256 352V256 128 96c0-12.4 7.2-23.7 18.4-29s24.5-3.6 34.1 4.4l192 160c7.3 6.1 11.5 15.1 11.5 24.6s-4.2 18.5-11.5 24.6l-192 160c-9.5 7.9-22.8 9.7-34.1 4.4s-18.4-16.6-18.4-29V352z"/> 
          
             <h5>{answer.memberquestiontext}</h5> 
          
          </div>
          <div class="para-text">
            <p>{answer.memberanswer}</p>
          </div>
       </div>
   )) }


        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 spouse-img">
          <div class="text-col">
            <img src={MemberInfoData.memberspouseimg} class="rounded" alt="Cinque Terre"  height="480px;" width="380px" />
          </div>
          <div class="para-text px-2">
            <h3 class="text-danger ">Meet The Spouse</h3>
            <h4 class="text-danger ">3 Things We should Know about You </h4>
            <p>{MemberInfoData.memberspouseinfo} </p>
          </div>
        </div>


      </div>
          
  {/* </div> */}
 </div>


  <FooterNav />

  </>

)

}

export default MemberDetailPage;