import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderNav from './header';
import { useState } from "react";
import axios from "axios";

const MemberAddQuestions = () =>
{
 
   const [allValues, setAllValues] = useState({
        txtQuestionText: ''
     });

    const changeHandler = e => 
    {
        setAllValues({...allValues, [e.target.name]: e.target.value})
    }
  
const handleSubmit = event => 
{ 
   event.preventDefault();
    const formData = new FormData(event.target); // reference to form element
     //console.table(formData);      
    const data = {}; // need to convert it before using not with XMLHttpRequest
    
         for (let [key, val] of formData.entries()) 
          {
            Object.assign(data, { [key]: val })
          }
          
          Object.assign(data, { ['action']:'saveQuestionData' });   

           //console.log(data);
          axios.post("https://codes.biglittleideas.com/bniapps/api/bni-apis.php", data)      
          .then(res => 
          {   
               console.log(res.data.status);
               if (res.data.status == true)
               {
                  alert(res.data.message);
               }
               //alert('Member successfully created');
                 //props.history.push("/student-list");
                //navigate("/allusers");
          
        })
          .catch(err => console.log(err));

}


   return (
    <>
      <HeaderNav />
  <div className="container mt-5 bg-light p-3 ">
  <div className="text-center p-4">
    <h3>Create Questions</h3>
  </div>
   
   
   <form onSubmit={handleSubmit}>
     <div className="row">
      <div className="col" style={{textAlign: 'left'}}>
        <label for="staticEmail2" className="sr-only"  > Question Title</label>
          <input type="text" name="txtQuestionText" className="form-control"   onChange={changeHandler} />
      </div>
    </div>
      
      

   <div className="row mt-3">
    <div className="col">
      <input type="submit" class="btn btn-primary" />
    </div>
  </div>
  
  </form>
</div>
  
    </>
  )

}

export default MemberAddQuestions;